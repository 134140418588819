import React, { useEffect,useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { findEnded } from '../../../redux/reducers/authSlice'
import FinalOutput from './FinalOutput'
import EndedOutput from '../EndInvite/EndedOutput'
import { snackbarContext } from '../../helpers/Snakbar'
import Loader from '../../helpers/Loader'
const FinalHome = ({previewId}) => {
  
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const params=useParams()
    // const id=params.username
    const setStateSnackbarContext=React.useContext(snackbarContext)
    const [hasEnded,setHasEnded]=useState(false)
    const [loading,setLoading]=useState(true)
    const [notFound,setNotFound]=useState(false)
    const [signedIn,setSignedIn]=useState(false)
    let id=""
    if(previewId){
      id=previewId
    }else{
      id=params.username
    }
    
    useEffect(()=>{
        dispatch(findEnded({id,setHasEnded,setLoading,setNotFound,setSignedIn}))
      },[id])

      if(notFound){
        return  navigate("/display/notfound")
      }

      if(signedIn){
        setStateSnackbarContext(
          true,
          "Please Signup",
          "warning"
      )
        return   navigate(`/signup/${id}`)
      }


  return (
    <>
 
    {loading ? (
      <Loader/>
    ) : hasEnded ? (
       <EndedOutput/>
    ) : (
      <FinalOutput  previewId={previewId}  />
    )}
  </>
  )
}

export default FinalHome