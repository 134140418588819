import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeRemaining = () => {
        const now = new Date();
        const target = new Date(targetDate);
        
        // Clear the time parts (set to midnight) to compare only dates
        now.setHours(0, 0, 0, 0);
        target.setHours(0, 0, 0, 0);

        const timeRemaining = target - now;
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));

        // If it's tomorrow (1 day left), show 1 day
        const remainingDays = days < 1 ? 1 : days;

        const isToday = days <= 0; // Check if it's the same day (ignoring time)

        return {
            days: remainingDays,
            isToday
        };
    };

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000 * 60); // Update every minute

        return () => clearInterval(timer);
    }, [targetDate]);

    return (
        <div className='countDownDiv' data-aos="zoom-in">
            {timeRemaining.isToday ? (
                <>
                    <h3>Finally, the day has arrived!</h3>
                    <h4>celebration begins 🎉</h4>
                </>
            ) : (
                <>
                    <h3>The wait is over, Countdown Begins</h3>
                    <p>{timeRemaining.days}</p>
                    <span>Day{timeRemaining.days > 1 ? 's' : ''} To Go</span>
                </>
            )}
        </div>
    );
};

export default CountdownTimer;
