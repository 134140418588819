import React,{useEffect, useState} from 'react'
import LockIcon from '@mui/icons-material/Lock';
import MailIcon from '@mui/icons-material/Mail';
import ErrorIcon from '@mui/icons-material/Error';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import inputErrors from '../helpers/validation'
import {useDispatch,useSelector} from 'react-redux'
import { singup,clearError, isSignedIn } from '../../redux/reducers/authSlice';
import {snackbarContext} from '../helpers/Snakbar'
import {useNavigate,useParams} from 'react-router-dom'
import { TabTitle } from '../helpers/GeneralFunctions';
import Loader from '../helpers/Loader';
const Signup = () => {
  TabTitle("Signup")
  const navigate=useNavigate()
  const params=useParams()
   const id=params.id
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const dispatch=useDispatch()
  const {error:backendError,loading}=useSelector((state)=>state.auth)
  const [notFound,setNotFound]=useState(false)
  const[tnc,settnc]=useState(false)
  const [values,setValues]=useState({
    email:"",
    password:"",
    confirmPassword:"",
    signUpCode:"",
    userCode:id
  })
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const handleChange=(event)=>{
    setValues({
        ...values,
        [event.target.name]: event.target.value
      });    
}

const handleSubmit=(e)=>{
  e.preventDefault();
  const validationErrors=inputErrors(values)
  setErrors(validationErrors)
  setSubmitting(true)
}  

const signupFunc=()=>{
  setSubmitting(false)
  dispatch(singup({values,navigate,setStateSnackbarContext}))
}

useEffect(()=>{
  if(isSubmitting){
    const noErrors = Object.keys(errors).length === 0;   
    if(noErrors){
       signupFunc()
    }
  }
  if(backendError){
    setStateSnackbarContext(
      true,
      backendError,
      "error"
    )
    dispatch(clearError())
  }
},[isSubmitting,errors,backendError])

useEffect(()=>{
 dispatch(isSignedIn({id,setNotFound}))
},[])

if(notFound){
  return  navigate("/display/notfound")
}
if(loading){
  return <Loader/>
}
const whatsappLink = `https://wa.me/919321060218?text=Hello, I need help regarding`;
  return (
    <div className="signup-app-container">
    <div className="signup-box-container">
    <div className="backdrop"> </div>
    <img src={process.env.PUBLIC_URL + '/wordmarklogo.png'} alt="aamantran-logo" className='tagd-logo' />
    <div className='login-content'>
        <h2 className='header-text'>Welcome!</h2>   
        <h2 className='header-text'>Sign Up</h2>  
       
    </div>
   
    <div className='inner-container'>
    <form className='login-form-container' onSubmit={handleSubmit}>
    <div className={errors.email?"input-box-error":"input-box"}>
      <input type="text"
      id='email'
       value={values.email}
      name='email'
      placeholder=' '
      className='input-field-login'
      onChange={handleChange}
       />
       <label htmlFor="email" className="input-label" >Email</label>
       <span className="label-icon"><MailIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.email &&  ( <small className="error-msg">{errors.email}</small>) }</div>

    <div className={errors.password?"input-box-error":"input-box"}>
      <input type="password"
      id='password'
      value={values.password}
      name='password'
      placeholder=' '
      className='input-field-login'
      onChange={handleChange}
       />
       <label htmlFor="password" className="input-label" >Password</label>
       <span className="label-icon"><LockIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.password &&  ( <small className="error-msg">{errors.password}</small>) }</div>

    <div className={errors.confirmPassword?"input-box-error":"input-box"}>
      <input type="password"
      id='confirmPassword'
      value={values.confirmPassword}
      name='confirmPassword'
      placeholder=' '
      className='input-field-login'
      onChange={handleChange}
       />
       <label htmlFor="confirmPassword" className="input-label" >Confirm Password</label>
       <span className="label-icon"><LockIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.confirmPassword &&  ( <small className="error-msg">{errors.confirmPassword}</small>) }</div>

    <div className={errors.signUpCode?"input-box-error":"input-box"}>
      <input type="text"
      id='signUpCode'
      value={values.signUpCode}
      name='signUpCode'
      placeholder=' '
      className='input-field-login'
      onChange={handleChange}
       />
       <label htmlFor="signUpCode" className="input-label" >Sign Up Code</label>
       <span className="label-icon"><VpnKeyIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.signUpCode &&  ( <small className="error-msg">{errors.signUpCode}</small>) }</div>
    <a className='forgot-password' href={whatsappLink}>Facing issues? Need Help</a>
    <p className='tnc-line'><input type="checkbox" className='tnc-checkbox' onChange={(e)=>settnc(e.target.checked)} /> <span className='py-2'> <a className='forgot-password' target='_blank' rel='noreferrer' href="/terms-and-condition">Agree with Terms and Conditions</a></span> </p>
    {tnc? <button className='login-submit-button' type='submit' >
       <div className='login-text'>Sign Up</div>
        <div className='forward-icon'><ArrowForwardIcon/></div>  
     </button>
     :
     <button className='login-submit-button-disabled' type='submit' disabled >
       <div className='login-text'>Sign Up</div>
        <div className='forward-icon'><ArrowForwardIcon/></div>  
     </button>
     } 
    </form>
    </div>
    </div>
    </div>
  )
}

export default Signup