import React, { useEffect, useState,useRef } from 'react'
import './forms.css'
import './background.css'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile, saveProfile } from '../../../redux/reducers/profileSlice';
import BackgroundContainer from './BackgroundContainer';
import CropperCom from './CropperCom';
import inputErrors from '../../helpers/validation'
// import { ReactComponent as Group4Icon } from '../../../images/Group4.svg';
import { ReactComponent as HeartIcon } from '../../../images/heartdivider.svg';
import Progress from './Progress';
import Loader from '../../helpers/Loader';
import { ChromePicker } from 'react-color';
const BasicDetailsForm = ({ onNext }) => {
    const formRef = useRef(null);
    const { weddingData: profileData, loading} = useSelector((state) => state.profile)
    const [values, setValues] = useState({
        brideName: "Bride Name",
        groomName: "Groom Name",
        weddingDate: "",
        hashtag: "",
        bannerImage: {
            url: "bg.png",
            public_id: ""
        },
        fontAlignment: "bottom",
        fontColor: "#f5f5f5",
        fontStyle: "normal"
    })
    const [isSubmitting, setSubmitting] = useState(false);
    const dispatch = useDispatch()
    const [defalutBgConatiner, setdefalutBgConatiner] = useState(false)
    const [errors, setErrors] = useState({})
    const [showCropper, setShowCropper] = useState(false)
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    function getCurrentDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const formatDate = (inputDate) => {
        const date = dayjs(inputDate);
        const formattedDate = date.format('DD MMMM YYYY');
        return formattedDate
    };

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    }

    const handleColorChange = (color) => {
        setValues({ ...values, fontColor: color.hex });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const validationErrors = inputErrors(values)
        setErrors(validationErrors)
        setSubmitting(true)
    }


    const savedata = () => {
        setSubmitting(false)
       dispatch(saveProfile({ values,onNext }))
    }

    useEffect(() => {   
        dispatch(getProfile())
         window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        setValues({ ...profileData })
    }, [profileData])

    useEffect(() => {
        if (isSubmitting) {
            const noErrors = Object.keys(errors).length === 0;
            if (noErrors) {
                savedata()
            }else{
                formRef.current.scrollIntoView({ behavior: "smooth" });
            }

        }
    }, [isSubmitting, errors])


  const handleDefaultBg = () => setdefalutBgConatiner(prevValue => !prevValue)

    const rerenderBg = (bg) => {
        setValues({
            ...values,
            bannerImage: {
                url: bg,
                public_id: values.bannerImage.public_id
            }
        })
    }

    const handleCropper = () => setShowCropper(prevValue => !prevValue)

    const internalCss = `
    .wed-detail-date::before,
.wed-detail-date::after {
    background-color:${values.fontColor}
}
 .preview-custom-icon path {
    fill:${values.fontColor} ; 
  }
  
    `

    const fontFamilies = [
        { value: 'Roboto, sans-serif', label: 'Roboto' },
        { value: 'Carter One, serif', label: 'Carter One' },
        { value: 'Playfair Display, serif', label: 'Playfair Display' },
        { value: 'Shrikhand, serif', label: 'Shrikhand' },
        { value: 'Oswald, sans-serif', label: 'Oswald' },
        { value: 'Montserrat, sans-serif', label: 'Montserrat' },
        { value: 'Lora, serif', label: 'Lora' },
        { value: 'Alice, serif', label: 'Alice' },
        { value: 'Delius Swash Caps, cursive', label: 'Delius Swash Caps' },
        { value: 'Black Ops One, sans-serif', label: 'Black Ops One' },
        { value: 'Charm, cursive', label: 'Charm' },
    ];

      if(loading){
        return <Loader/>
      }
      const toggleColorPicker = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    return (
        <>
        <Progress activeStep={0}  />
            <style>{internalCss}</style>
            <div className="form-screen">
                <div className="container mb-3">
                    <div className="row d-flex mx-2 row-containers">
                        <div className="col-lg-5  col-md-11 form-container">
                        <h3 className='form-heading'>Banner Deatils</h3>
                            <form className='form-div' onSubmit={handleSubmit} ref={formRef} >
                            
                                <div className={errors.groomName ? "form-input-div-error" : "form-input-div"}>
                                    <input type="search"
                                        name="groomName"
                                        value={values.groomName}
                                        onChange={handleChange}
                                        placeholder=' '
                                        className={errors.groomName ? "form-input-field-error" : "form-input-field"}
                                    />
                                    <label>Groom First Name <span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='error-wedding-div'>  {errors.groomName && (<small className="error-msg-wedding">{errors.groomName}</small>)}</div>

                                <div className={errors.brideName ? "form-input-div-error" : "form-input-div"}>
                                    <input type="search"
                                        name="brideName"
                                        value={values.brideName}
                                        onChange={handleChange}
                                        placeholder=' '
                                        className={errors.brideName ? "form-input-field-error" : "form-input-field"}
                                    />
                                    <label>Bride First Name  <span style={{ color: "red" }}> *</span></label>
                                </div>
                                <div className='error-wedding-div'>  {errors.brideName && (<small className="error-msg-wedding">{errors.brideName}</small>)}</div>
                                <div className='date-container'>
                                    <label >Wedding Date  <span style={{ color: "red" }}>*</span></label>
                                    <input
                                        type="date"
                                        name='weddingDate'
                                        value={values.weddingDate?values.weddingDate:getCurrentDate()}
                                        onChange={handleChange}
                                        min={getCurrentDate()}
                                        className='wedding-date-input'
                                    />
                                </div>


                                <div className={errors.hashtag ? "form-input-div-error" : "form-input-div"}>
                                    <input type="search"
                                        name="hashtag"
                                        value={values.hashtag}
                                        onChange={handleChange}
                                        placeholder=' '
                                        className={errors.hashtag ? "form-input-field-error" : "form-input-field"}
                                    />
                                    <label>Hashtag  <span className='label-hide'>[Eg Virushka,Sidkiara,Vickat]</span></label>
                                </div>
                                <div className='error-wedding-div'>  {errors.hashtag && (<small className="error-msg-wedding">{errors.hashtag}</small>)}</div>
                                <div className='file-input'>
                                    <label htmlFor="">Banner</label>
                                    <div className='banner-buttons'>
                                        <button type="button" className='default-banner-button' onClick={handleDefaultBg} >Select Default Image</button>
                                        <span>or</span>
                                        <button type="button" className='default-banner-button' onClick={handleCropper} >Upload Image</button>
                                    </div>
                                </div>
                                <div className='align-div'>
                                    <label htmlFor="fontAlignment">Font Alignment</label>
                                    <select
                                        id="fontAlignment"
                                        name="fontAlignment"
                                        value={values.fontAlignment}
                                        onChange={handleChange}
                                        className="form-input-field"
                                    >
                                        <option value="top" style={{ color: "black" }}>Top</option>
                                        <option value="bottom" style={{ color: "black" }}>Bottom</option>
                                    </select>
                                </div>

                                <div className="align-div">
                                    <label htmlFor="fontColor">Font Color</label>
                                    <div>
                                        {/* Color Box to Show Selected Color */}
                                        <div
                                            onClick={toggleColorPicker}
                                            className='color-picker'
                                            style={{
                                                backgroundColor: values.fontColor
                                            }}
                                        />

                                        {/* Display the color picker on click */}
                                        {displayColorPicker ? (
                                            <div style={{ position: 'absolute', zIndex: '2' }}>
                                                <div
                                                    style={{
                                                        position: 'fixed',
                                                        top: '0px',
                                                        right: '0px',
                                                        bottom: '0px',
                                                        left: '0px',
                                                    }}
                                                    onClick={handleClose}
                                                />
                                                <ChromePicker color={values.fontColor} onChange={handleColorChange} />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="align-div">
                                    <label htmlFor="fontStyle">Font Style</label>
                                    <select
                                        id="fontStyle"
                                        name="fontStyle"
                                        value={values.fontStyle}
                                        onChange={handleChange}
                                        className="form-input-field"
                                    >
                                      {fontFamilies.map((family) => (
                                    <option key={family.value} value={family.value} style={{ fontFamily: family.value }}>
                                                  {family.label}
                                          </option>
                                           ))}

                                      
                                    </select>
                                </div>
                                <button type='submit' className='form-submit-button'>Submit</button>
                            </form>
                        </div>
                    

                        {loading ? "loading" : <>
        

                            
                            <div className="col-lg-5 col-md-11 preview-container p-0 ">
                            <h3 className='form-heading text-center'>Preview</h3>
                            <img
                                    src={values.bannerImage ? values.bannerImage.url : ''}
                                    alt="banner-img"
                                    srcSet={`
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_640/') : ''} 1080w
  `}
                                    sizes="(max-width: 320px) 320px, 
                                       (max-width: 640px) 640px, 
                                    (max-width: 1080px) 1080px"
                                />
                                <div className={`wed-details-container  align-${values.fontAlignment}`}    style={{color: values.fontColor, fontFamily: values.fontStyle }}>
                                    <span className='wed-detail-name'>{values.groomName}</span>
                                    {/* <Group4Icon className="preview-custom-icon" /> */}
                                    <HeartIcon  className="preview-custom-icon"/>
                                    <span className='wed-detail-name'>{values.brideName}</span>
                                    <span className="wed-detail-date">{values.weddingDate ? formatDate(values.weddingDate) :"DD-MM-YYYY"} </span>
                                    <span className="wed-detail-hastag">{values.hashtag ? `#${values.hashtag}` : ""}</span>
                                </div>

                            </div>
                        </>}

                    </div>
                </div>
            </div>
            {defalutBgConatiner && <BackgroundContainer handleDefaultBg={handleDefaultBg} rerenderBg={rerenderBg} values={values} />}
            {showCropper && <CropperCom handleCropper={handleCropper} setValues={setValues} values={values} imageType="bannerImage" />}
        </>
    )
}

export default BasicDetailsForm