import React,{useState,createContext} from 'react'
import './switching.css'
import Login from './Login'
import Forgot from './Forgot'
import { useSelector } from 'react-redux'
import Loader from '../helpers/Loader'
export const AccountContext=createContext()
const Switching = () => {
  const {loading}=useSelector((state)=>state.auth)
    const [active,setActive]=useState("login")
    const switchToReset=()=>{
        setActive("reset")
     }
     const switchtologin=()=>{
       setActive("login")
    }
    const contextvalue={switchToReset,switchtologin}

    if(loading){
      return <Loader/>
    }

    return (
        <AccountContext.Provider value={contextvalue}> 
   <div className="app-container">
     <div className="box-container">
     <div className="backdrop"> </div>
     <img src="wordmarklogo.png" alt="" className='tagd-logo' />
     {active==="login" &&
    <>  <div className='login-content'>
        <h2 className='header-text'>Welcome!</h2>   
        <h2 className='header-text'>Sign in</h2>
    </div>
    </>
    } 
    {active==="reset" &&  
    <>  <div className='login-content'>
        <h2 className='reset-header-text'>Dont Worry!</h2>   
        <p className='smalltext'>We will send instructions on email </p>
    </div>
    <h2 className='reset-text'>Reset Password</h2>  
    </>
    } 
    <div className='inner-container'>
           {active==="reset" && <Forgot/>}
           {active==="login"&& <Login/>}
      </div>
     </div>
   
   </div>
   </AccountContext.Provider>
  )
}

export default Switching