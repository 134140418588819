import React, { useContext,useState,useEffect } from 'react'
import {AccountContext} from './Switching'
import './login.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import LockIcon from '@mui/icons-material/Lock';
import MailIcon from '@mui/icons-material/Mail';
import ErrorIcon from '@mui/icons-material/Error';
import inputErrors from '../helpers/validation'
import {useDispatch,useSelector} from 'react-redux'
import { login,clearError } from '../../redux/reducers/authSlice';
import {clearError as EventClearError} from '../../redux/reducers/eventSlice'
import {snackbarContext} from '../helpers/Snakbar'
import {useNavigate} from 'react-router-dom'
import {TabTitle} from '../helpers/GeneralFunctions'
const Login = () => {
   TabTitle("Login")
  const setStateSnackbarContext=React.useContext(snackbarContext)
  const dispatch=useDispatch()
  const navigate=useNavigate()
  const {error:backendError,isAuthenticated,}=useSelector((state)=>state.auth)
  const {error:EventError}=useSelector((state)=>state.event)
  const [values,setValues]=useState({
    email:"",
    password:""
  })
  const [errors, setErrors] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const {switchToReset}=useContext(AccountContext)
  
  const handleChange=(event)=>{
    setValues({
        ...values,
        [event.target.name]: event.target.value
      });    
}

const handleSubmit=(e)=>{
  e.preventDefault();
  const validationErrors=inputErrors(values)
  setErrors(validationErrors)
  setSubmitting(true);
}

const loginFunc=async()=>{
  setSubmitting(false)
 dispatch(login({values,navigate,setStateSnackbarContext}))
}

useEffect(()=>{
  if(isSubmitting){
    const noErrors = Object.keys(errors).length === 0;   
    if(noErrors){
       loginFunc()
    }
  }
  if(backendError){
    setStateSnackbarContext(
      true,
      backendError,
      "error"
    )
    dispatch(clearError())
  }

  if(isAuthenticated){
    navigate("/")
  }

},[isSubmitting,errors,backendError,isAuthenticated])

useEffect(()=>{
  if(EventError){
    dispatch(EventClearError())
  }
},[EventError])

const whatsappLink = `https://wa.me/919321060218?text=Hello, I need help regarding`;
   
  return (
   <form className='login-form-container' onSubmit={handleSubmit}>
    <div className={errors.email?"input-box-error":"input-box"}>
      <input type="text"
      id='email'
       value={values.email}
      name='email'
      placeholder=' '
      className='input-field-login'
      onChange={handleChange}
       />
       <label htmlFor="email" className="input-label" >Email</label>
       <span className="label-icon"><MailIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.email &&  ( <small className="error-msg">{errors.email}</small>) }</div>


    <div className={errors.password?"input-box-error":"input-box"}>
      <input type="password"
      id='password'
       value={values.password}
      name='password'
      placeholder=' '
      className='input-field-login'
       onChange={handleChange}
       />
       <label htmlFor="password" className="input-label" >Password</label>
       <span className="label-icon"><LockIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.password &&  ( <small className="error-msg">{errors.password}</small>) }</div>
    <span onClick={switchToReset} className='forgot-password' >Forgot Password?</span>
     <a className='forgot-password' href={whatsappLink}>Facing issues? Need Help</a>
     <button  className='login-submit-button' type='submit'>
     <span className='login-text'>Login</span>
     <div className='forward-icon'><ArrowForwardIcon/></div>  
     </button>
   </form>
  )
}

export default Login