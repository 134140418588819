import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { getProfileDetail } from '../../../redux/reducers/profileSlice'
import './finaloutput.css'
import { ReactComponent as Group4Icon } from '../../../images/Group4.svg';
import { ReactComponent as HeartIcon } from '../../../images/heartdivider.svg';
import { ReactComponent as AndIcon } from '../../../images/and.svg';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import dayjs from 'dayjs'
// import FavoriteIcon from '@mui/icons-material/Favorite';
import CountdownTimer from './CountdownTimer'
import EventSlider from './EventSlider'
import ReactPlayer from 'react-player'
import { snackbarContext } from '../../helpers/Snakbar'
import { getEventsById } from '../../../redux/reducers/eventSlice'
import { TabTitle } from '../../helpers/GeneralFunctions'
import FSharing from './FSharing'
import Loader from '../../helpers/Loader'
import Envelope from '../../helpers/Envelope'
import { ReactComponent as Divider } from '../../../images/divider.svg';
import FooterSlider from './FooterSlider'
const FinalOutput = ({previewId}) => {

  const setStateSnackbarContext = React.useContext(snackbarContext)
  const { weddingData: data, loading } = useSelector((state) => state.profile)
  const { events } = useSelector((state) => state.event)
  const [scrolledToEvents, setScrolledToEvents] = useState(false);
  const [envelopeScreen, SetEnvelopeScreen] = useState(true)
  const [values, setValues] = useState({
    bannerImage: "",
    brideName: "",
    groomName: "",
    hashtag: "",
    fontAlignment: "",
    weddingDate: "",
    fontColor: "",
    fontStyle: "",
    groomImage: "",
    groomFullName: "",
    groomParentDetail: "",
    brideImage: "",
    brideFullName: "",
    brideParentDetail: "",
    countDownDate: "",
    preWeddingLink: "",
    footerImage: "",
    specialMessage: "",
    specialMessage: "",
    instagramLink: "",
    facebookLink: "",
    theme: "",
    coverIcon: "",
    coverText: "",
    blessingMessage: ""
  })

  TabTitle(((values.groomName !== "Groom Name") && (values.BrideName !== "Bride Name")) ? `${values.groomName} & ${values.brideName} - Wedding Invitation` : "Aamantran Wedding Invitation")

  const params = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation();
  let id = params.username
  const queryParams = new URLSearchParams(location.search);
  const group = queryParams.get('group');

  if(previewId){
    id=previewId
 }else{
   id=params.username
 }

  useEffect(() => {
    dispatch(getProfileDetail({ id, navigate, setStateSnackbarContext }))
    dispatch(getEventsById({ id, group }))
  }, [id])

  useEffect(() => {
    setValues({ ...data })
  }, [data])

  const formatDate = (inputDate) => {
    const date = dayjs(inputDate);
    const formattedDate = date.format('DD MMMM YYYY');
    return formattedDate
  };
  const internalCss = `
    .banner-date::before,
.banner-date::after {
    background-color:${values.fontColor}
}
 .custom-icon path {
    fill:${values.fontColor} ; 
  }
    `
  const scrollToEvents = () => {
    const eventsSection = document.getElementById('events-section');
    if (eventsSection) {
      const eventsSectionTop = eventsSection.offsetTop;
      window.scrollTo({ top: eventsSectionTop, behavior: 'smooth' });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const getScrollButtonMessage = () => {
    return scrolledToEvents ? "Scroll to Top" : "Scroll to Events";
  };

  useEffect(() => {
    const handleScroll = () => {
      const eventsSection = document.getElementById('events-section');

      if (eventsSection) {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const viewportHeight = window.innerHeight;
        const eventsSectionTop = eventsSection.offsetTop;
        const triggerOffset = 0.8; // Adjust this value based on your preference (0.8 means 80% of the viewport height)

        // Check if the user has scrolled past the trigger point
        setScrolledToEvents(scrollPosition >= eventsSectionTop - triggerOffset * viewportHeight);
      }
    };


    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  if (loading) {
    return <Loader />
  }
  if (envelopeScreen) {
    return <Envelope SetEnvelopeScreen={SetEnvelopeScreen} theme={values.theme} coverIcon={values.coverIcon} coverText={values.coverText} />
  }

  return (
    <>
      <>
        <style>{internalCss}</style>
        <div className={`main-details-container ${values.theme}`}>
          <div className='banner'>
          <div class="banner-overlay"></div>
            <img
              id='bannerImage'
              src={values.bannerImage ? values.bannerImage.url : ''}
              className='banner-img'
              loading='lazy'
              srcSet={`
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.bannerImage ? values.bannerImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
              sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
              alt="banner-img" />

            <div className={`banner-details banner-${values.fontAlignment}`} style={{ color: values.fontColor, fontFamily: values.fontStyle }} >
              <h3>{values.groomName}</h3>
              {/* <Group4Icon className="custom-icon" /> */}
              <HeartIcon  className="custom-icon"/>
              <h3>{values.brideName}</h3>
              <div className="banner-date"  >
                {values.weddingDate ? formatDate(values.weddingDate) : "DD-MM-YYYY"}
              </div>
              {values.hashtag && <div className='banner-hastag'>{values.hashtag === "hashtag" ? "" : `# ${values.hashtag}`}</div>}
            </div>
          </div>
          {values.blessingMessage && <div className="blessing-container" data-aos="zoom-in">
            <div className="b-divider">
              <Divider />
            </div>
            <div class="blessing-content">
              <span>{values.blessingMessage}</span>
            </div>
            <div className="b-divider">
              <Divider />
            </div>
          </div>}
          <h1 className={values.blessingMessage?"main-heading first-heading":"main-heading"} >The Lovely Couple</h1>
          <div className="bg-container">
            <div className="bg-card" data-aos="fade-up">
              <img
                src={values.groomImage ? values.groomImage.url : ''}
                className='bg-card-img'
                loading='lazy'
                srcSet={`
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.groomImage ? values.groomImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
                sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
                alt="groom-image" />

              <h3>{values.groomFullName}</h3>
              {values.groomParentDetail !== "Groom Parent Detail" && values.groomParentDetail !== "" && (
                <h4>{`(S/O ${values.groomParentDetail})`}</h4>)}
            </div>
            {/* <FavoriteIcon className='bg-icon'  /> */}
            <div className="and-icon" data-aos="fade-up">
              <AndIcon />
            </div>
            <div className="bg-card" data-aos="fade-up">
              <img
                src={values.brideImage ? values.brideImage.url : ''}
                className='bg-card-img'
                loading='lazy'
                srcSet={`
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.brideImage ? values.brideImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
                sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
                alt="bride-image" />
              <h3>{values.brideFullName}</h3>
              {values.brideParentDetail !== "Bride Parent Detail" && values.brideParentDetail !== "" && (
                <h4>{`(D/O ${values.brideParentDetail})`}</h4>)}
            </div>
          </div>

          <div className="sepration"> <Group4Icon className="sepration-icon" /></div>
          <div className="countdown-video-div">
            {values.countDownDate &&
                <CountdownTimer targetDate={values.countDownDate} />
              }
            {values.preWeddingLink &&
              <>
                <ReactPlayer url={values.preWeddingLink}
                  className="video-container"
                  playing={false}
                  playsinline
                  controls={true}
                  muted={false}
                  volume={1}
                  data-aos="zoom-in-up"
                />
              </>
            }
          </div>
          <EventSlider events={events} />
          <div className="sepration mt-4"> <Group4Icon className="sepration-icon" /></div>
          <FooterSlider images={values.footerImages}  />
          {/* {values.footerImage && values.footerImage.url && <div className='footer-image-final' data-aos="fade-up">
            <img
              src={values.footerImage ? values.footerImage.url : ''}
              className='memorable-pic'
              loading='lazy'
              srcSet={`
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_320/') : ''} 320w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_640/') : ''} 640w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_1080/') : ''} 1080w,
    ${values.footerImage ? values.footerImage.url.replace('/upload/', '/upload/w_1920/') : ''} 1920w
  `}
              sizes="(max-width: 320px) 320px, 
         (max-width: 640px) 640px, 
         (max-width: 1080px) 1080px, 
         1920px"
              alt="footer-image" />
          </div>} */}
          {values.specialMessage && <div className='special-message' data-aos="fade-up">
            <h3>“ {values.specialMessage} ”</h3>
            <h4>By - {values.groomName} & {values.brideName}</h4>
          </div>}

          <div className="social-media" data-aos="fade-up">
            {values.instagramLink || values.facebookLink ? (
              <>
                {/* <h2 className='main-heading'>Do visit Our Social Media</h2> */}
                <div className="icons-div">
                  {values.instagramLink && (
                    <a href={values.instagramLink} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-instagram social-icon"></i>
                    </a>
                  )}
                  {values.facebookLink && (
                    <a href={values.facebookLink} target="_blank" rel="noopener noreferrer">
                      <i class="fa-brands fa-facebook social-icon"></i>
                    </a>
                  )}
                </div>
              </>
            ) : null}
          </div>
          {!previewId &&  <FSharing groomName={values.groomName} brideName={values.brideName} userCode={id} group={group}  /> }
          <div className="footer">
            <h3>Created with ❤️ at Aamantran</h3>
          </div>
          <div className="scroll-button" onClick={scrolledToEvents ? scrollToTop : scrollToEvents}>
            {scrolledToEvents ? <ArrowUpwardIcon className='up-down-arrow' /> : <ArrowDownwardIcon className='up-down-arrow' />}
            <div className="scroll-button-message">{getScrollButtonMessage()}</div>
          </div>
        </div>
      </>
    </>
  )
}

export default FinalOutput