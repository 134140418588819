import React from 'react'
import { RWebShare } from "react-web-share";
import IosShareIcon from '@mui/icons-material/IosShare';

const Esharing = ({ brideGroomName }) => {
  const shareUrl = window.location.href;
  const message = brideGroomName
      ? `Thank you for making our wedding so special! 🫶\n\nYou can view all the post-wedding links here:\n\n${brideGroomName} - Wedding Links:\n${shareUrl}`
      : `Thank you for making our wedding so special! 🫶\n\nYou can view all the post-wedding links here:\n\n${shareUrl}`;

  return (
      <RWebShare
          data={{
              text: message,
              url: shareUrl,
              title: "Wedding Links",
          }}
      >
          <IosShareIcon className='ended-share-icon' />
      </RWebShare>
  );
};

export default Esharing