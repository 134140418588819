import React, { useEffect, useState, useContext } from 'react'
import MailIcon from '@mui/icons-material/Mail';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import inputErrors from '../helpers/validation'
import { useDispatch,useSelector } from 'react-redux';
import { forgotPassword ,clearError} from '../../redux/reducers/authSlice';
import {snackbarContext} from '../helpers/Snakbar'
import { AccountContext } from './Switching';
const Forgot = () => {
    const dispatch=useDispatch()
    const {error:backendError}=useSelector((state)=>state.auth)
    const setStateSnackbarContext=React.useContext(snackbarContext)
   const {switchtologin}=useContext(AccountContext)
    const [values,setValues]=useState({
        email:""
    })
    const [isSubmitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const handleSubmit=(e)=>{
        e.preventDefault()
        const validationErrors=inputErrors(values)
         setErrors(validationErrors)
         setSubmitting(true);
    }
    const handleChange=(event)=>{
        setValues({
            ...values,
            [event.target.name]: event.target.value
          });    
    }

    const resetFunc=()=>{
        setSubmitting(false)
        dispatch(forgotPassword({values,setStateSnackbarContext,switchtologin}))
    }
    useEffect(()=>{
    if(isSubmitting){
        const noErrors = Object.keys(errors).length === 0;   
        if(noErrors){
            resetFunc()
         }
    }
    if(backendError){
        setStateSnackbarContext(
          true,
          backendError,
          "error"
        )
        dispatch(clearError())
      }
    },[isSubmitting,backendError,errors])
  return (
    <form className='login-form-container' onSubmit={handleSubmit}>
 <div className={errors.email?"input-box-error":"input-box"}>
      <input type="text"
      id='email'
       value={values.email}
      name='email'
      placeholder=' '
      className='input-field-login'
      onChange={handleChange}
       />
       <label htmlFor="email" className="input-label" >Email</label>
       <span className="label-icon"><MailIcon/></span>
        <span className="error-label-icon"><ErrorIcon/></span>
    </div>
    <div>  {errors.email &&  ( <small className="error-msg">{errors.email}</small>) }</div>
    <button  className='login-submit-button' type='submit'>
     <span className='login-text'>Submit</span>
     <div className='forward-icon'><ArrowForwardIcon/></div>  
     </button>
     <span onClick={switchtologin} className='forgot-password' >Get Back To Login Page <ArrowForwardIcon/></span>
    </form>
  
  )
}

export default Forgot