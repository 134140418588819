import React from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import FinalHome from '../FinalOutput/FinalHome';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './trialpage.css'
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from 'react-redux';
import { setNewTheme } from '../../../redux/reducers/profileSlice';
const TrialPage = () => {
    const { theme } = useParams();
      const location = useLocation();
      const navigate=useNavigate();
      const queryParams = new URLSearchParams(location.search);
      const themeid = queryParams.get('theme');
      const dispatch = useDispatch()
      const setTheme=()=>{
        dispatch(setNewTheme({themeid,navigate}))
        navigate("/details")
      }

  return (
    <>
    <div className='preview-box'>
    <div className='preview-text'>preview mode <VisibilityIcon className='visible-icon'/> </div>
       <NavLink to="/demo-wedding-invitation" className="navlink-use"><CancelIcon  className='cancel-use'  /> </NavLink> 
        <FinalHome previewId={theme}/>
        <button className='use-button' onClick={setTheme}>Use this Theme <ArrowForwardIcon className='arrow-use' /></button>
        </div>
    </>
  )
}

export default TrialPage